import React from 'react'
import { DiscussionEmbed } from 'disqus-react'
import config from '../utils/config'

export default function Comment({ title, slug, theme }) {
  const disqusConfig = {
    shortname: config.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
    theme: theme,
  }

  return <DiscussionEmbed {...disqusConfig} /> //<div ref={commentBox} className="comments">
}
